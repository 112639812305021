<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script type="text/javascript">

export default{
  name:"app"
}
</script>