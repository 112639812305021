import api from '@/interface/baseApi'

//获取所有地区树形数据
export function getAllAreas() {
    return api({
        url: '/publics/areas/getAllAreas',
        method: 'post',
        data:{}
    })
}

//获取所有地区数据
export function getAreas() {
    return api({
        url: '/publics/areas/getAreas',
        method: 'post',
        data:{}
    })
}
