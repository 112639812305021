<template>
	<div class="baoyang-payload">

		<div class="psearch">
			<van-sticky>
				<van-search 
					v-model="search.kws" 
					placeholder="请输入搜索关键词" 
					@search="onSearch"
				/>
			</van-sticky>
		</div>

		<div v-if="pageItem.length > 0" class="pitem">
			<van-list
				v-model="querys.loading"
				:finished="querys.finished"
				finished-text="没有更多了"
				@load="onNextPage"
			>
				<template v-for="(item,idx) in pageItem" >
					<van-cell>
						<template slot="icon">
							<van-image
								class="pimage"
								width="60"
								height="60"
								:src="item.poster"
							/>
						</template>
						<template slot="title">
							{{item.slogan}}
						</template>
						<template slot="label">
							<div class="prow" v-if="item.carCode != ''">车辆型号：{{item.carCode}}</div>
							<div class="prow" v-if="item.engine !=''">发动机型号：{{item.engine}}</div>
							<div class="prow" v-if="item.oserverName != ''">服务项目：{{item.oserverName}}</div>
							<div class="prow" v-if="item.volume != ''">机油用量：{{item.volume}}L</div>

							<div class="prow money">活 动 价：<span class="num">{{formatMoney(item.money)}}元</span></div>
						</template>
						<template slot="right-icon">
							<div class="pright">
								<div>
									<!-- <van-icon 
										v-if="idx == 0"
										class="selected" 
										name="circle" 
										size="20px"
										color="#909399"
									/>
										<van-icon 
										v-if="idx == 1"
										class="selected" 
										name="passed" 
										size="20px" 
										color="#67C23A"
									/> -->
								</div>

								<van-tag 
									class="send"
									type="primary"
									size="medium"
									@click="handleSend(idx)"
								> 发送 </van-tag>

							</div>
						</template>
					</van-cell>
				</template>
			</van-list>
		</div>

		<div v-else class="pitem">
			<div v-if="querys.loading" class="load">
				<van-loading 
					size="36px"
					type="spinner"
				/>
			</div>
			<van-empty v-else description="暂未设置特惠车型!" />
		</div>

		<!--分享弹框-->
		<van-popup 
			v-model="send.show" 
			position="bottom" 
			:style="{ height: '60vh' }" 

		>
			<div class="send-payload">
				<div class="title">请选择发送方式</div>
				<div class="content">
					<van-row>
						<van-col :span="12">
							<div class="thalf">
								<div class="stext">
									<div class="stitle">{{send.text.title}}</div>
									<div class="slink">{{send.text.url}}</div>
								</div>
							</div>
						</van-col>
						<van-col :span="12">
							<div class="thalf">
								<div class="tmini">
									<div class="ttitle">{{send.mini.title}}</div>
									
									<van-image
										class="timage"
										width="150"
										height="150"
										:src="send.mini.image"
									/>
								</div>
							</div>
						</van-col>
					</van-row>
				</div>
				<van-tabbar v-model="send.type">
					<van-tabbar-item icon="link-o" @click="handlClickShare('text')">发送文字链接</van-tabbar-item>
					<van-tabbar-item icon="miniprogram-o" @click="handlClickShare('mini')">发送小程序</van-tabbar-item>
				</van-tabbar>
			</div>
		</van-popup>
	</div>
</template>

<script type="text/javascript">
import {getSpecial} from '@/interface/oms/operate/server';
export default {
	data(){
		return {
			querys:{
				loading: false,
				finished: true
			},
			send:{
				type: '',
				show: false,
				currentIdx: '',
				text:{
					title: '',
					url: ''
				},
				mini:{
					title: '',
					image: ''
				}	
			},
			search:{
				kws: '',
				page: 1,
				psize: 10,
				orby: '',
				sort: '',
			},
			pageItem:[]
		}
	},
	created: function() {
		this.onSearch();
	},
	methods:{
		handleSend: function(idx){
			let current = this.pageItem[idx];

			let title = current.slogan+' '+current.oserverName+' ('+current.money+'元)';

			this.send.text = {
				title: title,
				url: '分享短连接',
			}
			this.send.mini = {
				title: title,
				image: current.poster,
				url: '/pweibao/baoyang/specialInfo/specialInfo?planId='+current.planId+'&thirdCode=qywx'
			}

			this.send.currentIdx = idx;
			this.send.show = true;
		},
		handlClickShare: function(type){
			this.$toast('分享功能开发中,请等待...!');
		},
		onSearch: function(){
			this.search.page = 1;
			this.pageItem = [];

			this.getPageData(this.search);
		},
		onNextPage: function(){
			this.search.page = this.search.page + 1;

			this.getPageData(this.search);
		},
		getPageData: function(search){
			this.querys.loading = true;
			getSpecial(search)
				.then(res=>{
					if( res.status && ('items' in res.response) ){

						if( res.response.items.length > 0 ){
							let _this = this;
							res.response.items.forEach( function(item){
								let row = {
									planId: item.planId,
									carCodeId:item.carCodeId,
									carCode: item.carCode,
									engine: item.engine,
									money: item.money,
									oserverId: item.oserverId,
									oserverName: item.oserverName,
									volume: item.volume,
									poster: item.poster,
									slogan: item.slogan,
								}

								_this.pageItem.push(row);
							})
						}

						this.querys.finished = this.pageItem.length >= res.response.total;
					}

					this.querys.loading = false;
				})
				.catch(err=>{})
		},
		formatMoney: function(money){
			return parseFloat(money).toFixed(2);
		}
	}
}
</script>