import Vue from 'vue'
import VueRouter from "vue-router";

Vue.use(VueRouter)
import {routerWWW} from '@/router/www';
import {routerInsure} from '@/router/insure';
import {routerOms} from '@/router/oms';

const router= new VueRouter({
    mode:'history',
    routes: [
        {
            path: '/index',
            name:'云卡官网',
            meta: {
                show: true,
                title:'云卡官网'
            },
            component: () => import("@/pages/www/layout"),
            children: routerWWW
        },
        {
            path: '/insure',
            name:'保司推修',
            meta: {
                show: true,
                title:'保司推修'
            },
            component: () => import("@/pages/insure/layout"),
            children: routerInsure
        },
        {
            path: '/oms',
            name:'运营商城',
            meta: {
                show: true,
                title:'运营商城'
            },
            component: () => import("@/pages/oms/layout"),
            children: routerOms
        },
        //重定向，在项目跑起来的时候，访问“/”,立马让他重定向到首页
        {
            path:'*',
            redirect:'/home'
        }
    ]
})
export default router
