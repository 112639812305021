<template>
	<div class="malloms-payload">

		<div class="pitem">
			<template v-for="(item,idx) in pageItem" >
				<div class="ptitle">{{item.label}}</div>
				<van-cell>
					<template slot="icon">
						<van-image
							class="pimage"
							width="100"
							height="60"
							:src="item.image"
						/>
					</template>
					<template slot="title">
						{{item.title}}
					</template>
					<template slot="label">
						{{formatTime(item.startTime)}} / {{formatTime(item.endTime)}}
					</template>
					<template slot="right-icon">
						<div class="pright">
							<!-- <div>
								<van-icon 
									v-if="idx == 0"
									class="selected" 
									name="circle" 
									size="20px"
									color="#909399"
								/>
									<van-icon 
									v-if="idx == 1"
									class="selected" 
									name="passed" 
									size="20px" 
									color="#67C23A"
								/>
							</div> -->

							<van-tag 
								class="send"
								type="primary"
								size="medium"
								@click="handleSend(idx)"
							> 发送 </van-tag>

						</div>
					</template>
				</van-cell>
			</template>
		</div>

		<!--分享弹框-->
		<van-popup 
			v-model="send.show" 
			position="bottom" 
			:style="{ height: '60vh' }" 

		>
			<div class="send-payload">
				<div class="title">请选择发送方式</div>
				<div class="content">
					<van-row>
						<van-col :span="12">
							<div class="thalf">
								<div class="stext">
									<div class="stitle">{{send.text.title}}</div>
									<div class="slink">{{send.text.url}}</div>
								</div>
							</div>
						</van-col>
						<van-col :span="12">
							<div class="thalf">
								<div class="tmini">
									<div class="ttitle">{{send.mini.title}}</div>
									
									<van-image
										class="timage"
										width="150"
										height="150"
										:src="send.mini.image"
									/>
								</div>
							</div>
						</van-col>
					</van-row>
				</div>
				<van-tabbar v-model="send.type">
					<van-tabbar-item icon="link-o" @click="handlClickShare('text')">发送文字链接</van-tabbar-item>
					<van-tabbar-item icon="miniprogram-o" @click="handlClickShare('mini')">发送小程序</van-tabbar-item>
				</van-tabbar>
			</div>
		</van-popup>
	</div>
</template>

<script type="text/javascript">
import {lottery, invite, signin} from '@/interface/oms/operate/active';
export default {
	data(){
		return {
			querys:{
			},
			send:{
				type: '',
				show: false,
				currentIdx: '',
				text:{
					title: '',
					url: ''
				},
				mini:{
					title: '',
					image: ''
				}	
			},
			pageItem:[]
		}
	},
	created: function() {
		this.getLottery();
		this.getInvite();
		this.getSignin();
	},
	methods:{
		handleSend: function(idx){
			let current = this.pageItem[idx];

			let title = current.title;

			this.send.text = {
				title: title,
				url: '分享短连接',
			}
			this.send.mini = {
				title: title,
				image: current.image,
				url: '/pweibao/baoyang/specialInfo/specialInfo?planId='+current.planId+'&thirdCode=qywx'
			}

			this.send.currentIdx = idx;
			this.send.show = true;
		},
		handlClickShare: function(type){
			this.$toast('分享功能开发中,请等待...!');
		},
		getLottery: function(){
			lottery()
				.then(res=>{
					if( res.status && ('id' in res.response) ){
						let info = res.response;
						let row = {
							label: '抽奖活动',
							key: 'lottery',
							id: info.id,
							title: info.title,
							startTime: info.startTime,
							endTime: info.endTime,
							image: info.adsImg,
						}

						this.pageItem.push(row);
					}
				})
				.catch(err=>{})
		},
		getSignin: function(){
			signin()
				.then(res=>{
					if( res.status && ('id' in res.response) ){
						let info = res.response;
						let row = {
							label: '每日签到',
							key: 'signin',
							id: info.id,
							title: info.title,
							startTime: info.startTime,
							endTime: info.endTime,
							image: info.adsImg,
						}

						this.pageItem.push(row);
					}
				})
				.catch(err=>{})
		},
		getInvite: function(){
			invite()
				.then(res=>{
					if( res.status && ('id' in res.response) ){
						let info = res.response;
						let row = {
							label: '邀请好友',
							key: 'invite',
							id: info.id,
							title: info.title,
							startTime: info.startTime,
							endTime: info.endTime,
							image: info.adsImg,
						}

						this.pageItem.push(row);
					}
				})
				.catch(err=>{})
		},
		formatTime: function(dt){
			return dt.substr(0,10);
		}
	}
}
</script>