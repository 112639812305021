<template>
	<div>
		<van-tabbar v-model="tabbar">
			<van-tabbar-item name="item" icon="comment" @click="handleTabbar('item')">已推修</van-tabbar-item>
			<van-tabbar-item name="add" icon="add" @click="handleTabbar('add')">推修</van-tabbar-item>
			<van-tabbar-item name="mine" icon="user-o" @click="handleTabbar('mine')">我的</van-tabbar-item>
		</van-tabbar>
	</div>
</template>
<script type="text/javascript">
export default {
	data(){
		return {
			tabbar: '',
		}
	},
	created: function() {
		this.setInit();
	},
	methods:{
		setInit: function(){
			let path = this.$route.path;

			if( path == '/insure/baoxiu' ){
				this.tabbar = 'item';
			}else if( path == '/insure/baoxiuform' ){
				this.tabbar = 'add';
			}else if( path == '/insure/mine' ){
				this.tabbar = 'mine';
			}
		},
		handleTabbar: function(tab){
			if( tab == 'item' ){
				this.$router.push('/insure/baoxiu');
			}else if(tab == 'add'){
				this.$router.push('/insure/baoxiuform');
			}else if(tab == 'mine'){
				this.$router.push('/insure/mine');
			}		
		}
	}
}
</script>