import api from '@/interface/baseApi'

//获取特惠车型
export function getSpecial(params) {
    return api({
        url: '/oms/operate/server/getSpecial',
        method: 'get',
        params:{
        	page: ('page' in params) ? params.page : 1,
			pageSize: ('psize' in params) ? params.psize : 20,
			kws: ('kws' in params) ? params.kws : '',
			orby: ('orby' in params) ? params.orby : '',
			sort: ('sort' in params) ? params.sort : '',
        }
    })
}

