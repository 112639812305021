<template>
	<div class="oms-container">
		<div class="oms-header">
			<Tabbar />
		</div>

		<router-view></router-view>
	</div>
</template>
<script type="text/javascript">
import Tabbar from '@/pages/oms/tabbar';

import '@/style/oms.css';
export default {
	components:{
		Tabbar
	},
	data(){
		return {
			
		}
	},
	created: function() {
	},
	methods:{

	}
}
</script>