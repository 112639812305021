/***运营商城***/
const routerOms = [
	{
        path: '/oms',
        name:'oms',
        meta: {
            show: true,
            title:'运营商城'
        },
        component: () => import('@/pages/oms/index')
    },
    {
        path: '/oms/mallproduct',
        name:'mallproduct',
        meta: {
            show: true,
            title:'商城产品'
        },
        component: () => import('@/pages/oms/mallproduct/index')
    },
    {
        path: '/oms/malloms',
        name:'malloms',
        meta: {
            show: true,
            title:'商城活动'
        },
        component: () => import('@/pages/oms/malloms/index')
    },
    {
        path: '/oms/special',
        name:'baoyaoSpecial',
        meta: {
            show: true,
            title:'特惠保养'
        },
        component: () => import('@/pages/oms/baoyang/special')
    },
]

export {
	routerOms
}