<template>
	<div class="oms-payload">
		
		<!--主要出入-->
		<div class="card-item">
			<div class="card-header">小程序入口页</div>
			<div class="card-payload">
				<van-grid>
					<template v-for="(item,idx) in appItem">
						<van-grid-item 
							:icon="item.icon" 
							:text="item.label" 
							@click="handleSend(idx)"
						/>
					</template>
				</van-grid>
			</div>
		</div>

		<!--分享弹框-->
		<van-popup 
			v-model="send.show" 
			position="bottom" 
			:style="{ height: '60vh' }" 

		>
			<div class="send-payload">
				<div class="title">入口页只能分享小程序</div>
				<div class="content">
					<div class="thalf onehalf">
						<div class="tmini">
							<div class="ttitle">{{send.mini.title}}</div>
							
							<van-image
								class="timage"
								width="150"
								height="150"
								:src="send.mini.image"
							/>
						</div>
					</div>
				</div>

				<van-tabbar v-model="send.type">
					<van-tabbar-item icon="miniprogram-o" @click="handlClickShare('mini')">发送小程序</van-tabbar-item>
				</van-tabbar>
			</div>
		</van-popup>

	</div>
</template>

<script type="text/javascript">
export default {
    data(){
    	return {
    		appItem:[
    			{label:'小程序首页', key:'index', icon:'miniprogram-o', url: 'pages/index/index'},
    			{label:'门店', key:'shop', icon:'shop-o', url: 'pages/tabbar/shop/shop'},
    			{label:'商城', key:'mall', icon:'shopping-cart-o', url: 'pages/tabbar/pmall/pmall'},
    			{label:'领券', key:'coupon', icon:'coupon-o', url: 'pages/oms/coupon/operate/index/index'},

    			{label:'保养', key:'baoyang', icon:'logistics', url: 'pages/tabbar/baoyang/baoyang'},
    			{label:'保养方案', key:'plan', icon:'brush-o', url: 'pweibao/plans/plan/plan'},
    			{label:'特惠车型', key:'special', icon:'newspaper-o', url: 'pweibao/baoyang/special/special'},
    		],
    		send:{
				type: '',
				show: false,
				currentIdx: '',
				mini:{
					title: '',
					image: ''
				}	
			},
    		//企业ID
			appid: 'ww9c248144cc026ba2',

			//应用ID
			APP_ID: 1000006,
			APP_SECRET:"iRn5mHpq2-Wr45QfAzv4ZB6BDrfSeS3BSR4h_VQyxec",
    	}
    },
    created: function() {
        this.wxinit();
    },
    methods:{
    	handleSend: function(idx){
			let current = this.appItem[idx];

			this.send.mini = {
				title: current.label,
				image: current.image,
				url: current.url
			}

			this.send.currentIdx = idx;
			this.send.show = true;
		},
		handlClickShare: function(type){
			this.$toast('分享功能开发中,请等待...!');
		},
    	wxinit: function(){
   //  		wx.config({
			// 	beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
			// 	debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
			// 	appId: 'ww9c248144cc026ba2', // 必填，企业微信的corpID，必须是本企业的corpID，不允许跨企业使用
			// 	timestamp: '', // 必填，生成签名的时间戳
			// 	nonceStr: '', // 必填，生成签名的随机串
			// 	signature: '',// 必填，签名，见 附录-JS-SDK使用权限签名算法
			// 	jsApiList: [] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
			// });

    		// config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
   //  		wx.ready(function(){
   //  			console.log(1233);
   //  			setTimeout(()=>{
   //  				wx.agentConfig({
			// 			corpid: 'ww9c248144cc026ba2', // 必填，企业微信的corpid，必须与当前登录的企业一致
			// 			agentid: '1000009', // 必填，企业微信的应用id （e.g. 1000247）
			// 			timestamp: '', // 必填，生成签名的时间戳
			// 			nonceStr: '', // 必填，生成签名的随机串
			// 			signature: '',// 必填，签名，见附录-JS-SDK使用权限签名算法
			// 			jsApiList: ['getContext'], //必填，传入需要使用的接口名称
			// 			success: function(res) {
			// 			    // 回调
			// 			    console.log('agentConfig success');
			// 			},
			// 			fail: function(res) {
			// 				console.log('agentConfig fail:', res);
			// 			}
			// 		});
   //  			}, 1000)
    			
			// });

			// wx.error(function(res){
			//     // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
			//     console.log(res);
			// });

   //  		ww.register({
			// 	corpId: 'ww9c248144cc026ba2',       // 必填，当前用户企业所属企业ID
			// 	agentId: 1000006,                  // 必填，当前应用的AgentID
			// 	jsApiList: ['getExternalContact'], // 必填，需要使用的JSAPI列表
			// 	getConfigSignature,                // 必填，根据url生成企业签名的回调函数
			// 	getAgentConfigSignature            // 必填，根据url生成应用签名的回调函数
			// })

			// async function getConfigSignature(url) {
			// 	// 根据 url 生成企业签名
			// 	// 生成方法参考 https://developer.work.weixin.qq.com/document/14924
			// 	return { timestamp, nonceStr, signature }
			// }

			// async function getAgentConfigSignature(url) {
			// 	// 根据 url 生成应用签名，生成方法同上，但需要使用应用的 jsapi_ticket
			// 	return { timestamp, nonceStr, signature }
			// }
			
    	}
    }
}
</script>