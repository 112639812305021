import api from '@/interface/baseApi'

//列表
export function get(params) {
    return api({
        url: '/insure/fixorder/get',
        method: 'get',
        params:{
        	tel: params.tel,
			page: ('page' in params) ? params.page : 1,
			psize: ('psize' in params) ? params.psize : 20,
			kws: ('kws' in params) ? params.kws : '',
        }
    })
}

//总计
export function total(params) {
    return api({
        url: '/insure/fixorder/total',
        method: 'post',
        data:{
            tel: params.tel,
        }
    })
}

//申请
export function apply(form) {
    return api({
        url: '/insure/fixorder/apply',
        method: 'post',
        data:{
            insureCode: form.insureCode,
            idCard: form.idCard,
            shopId: form.shopId,
            address: form.address,
            checker: form.checker,
            checkerTel: form.checkerTel,
            apply: form.apply,
            applyTel: form.applyTel,
            note: form.note,
            attach: form.attach,
            params: form.params,
        }
    })
}
