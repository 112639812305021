import api from '@/interface/baseApi'

//发送验证码
export function sendTelCode(tel) {
    return api({
        url: '/insure/inauth/sendTelCode',
        method: 'post',
        data:{
            tel: tel
        }
    })
}

//登录
export function login(params) {
    return api({
        url: '/insure/inauth/login',
        method: 'post',
        data:{
            tel: params.tel,
            code: params.code,
            encryCode: params.encryCode,
            passwd: params.passwd,
        }
    })
}
