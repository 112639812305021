<template>
<div>
    <Header/>
    <van-tabbar v-model="active" active-color="#ef803e" inactive-color="#494949" route >
        <van-tabbar-item  replace to="/home">
            <div><span>首页</span></div>
            <template #icon="props">
                <img :src="props.active ? icon.indexActive : icon.indexInactive"/>
            </template>
        </van-tabbar-item>
        <van-tabbar-item   to="/system">
            <div><span>云卡系统</span></div>
            <template #icon="props">
                <img
                     :src="props.active ? icon.sytemActive : icon.systemInactive"/>
            </template>

        </van-tabbar-item>
        <van-tabbar-item id="tab-text"   to="/union">
            <div style="margin-bottom: 0.1rem"><span>云卡联盟</span></div>
            <template #icon="props">
                <img style="width:3.33rem;height:3.33rem;border-radius: 50%;margin-top:-1.7rem"
                        :src="props.active ? icon.unionActive : icon.unionInactive"/>
            </template>
        </van-tabbar-item>
        <van-tabbar-item   to="/join">
            <div><span>加盟合作</span></div>
            <template #icon="props">
                <img :src="props.active ? icon.cooperateActive : icon.cooperateInactive"/>
            </template>
        </van-tabbar-item>
        <van-tabbar-item   to="/about">
            <div><span>关于我们</span></div>
            <template #icon="props">
                <img :src="props.active ? icon.aboutActive : icon.aboutInactive"/>
            </template>
        </van-tabbar-item>
    </van-tabbar>
    <router-view></router-view>
    <Footer/>
</div>
</template>

<script>
import Header from "@/components/Header"
import Footer from "@/components/Footer"
import indexActive from '@/assets/tabbar/icon_homepage_yellow.png'
import indexInactive from "@/assets/tabbar/icon_homepage_gray.png"
import unionActive from "@/assets/tabbar/icon_yuntruck_tabbar.png"
import unionInactive from "@/assets/tabbar/icon_yuntruck_tabbar.png"
import sytemActive from "@/assets/tabbar/icon_system_yellow.png"
import systemInactive from "@/assets/tabbar/icon_system_gray.png"
import cooperateActive from '@/assets/tabbar/icon_cooperation_yellow.png'
import cooperateInactive from '@/assets/tabbar/icon_cooperation_gray.png'
import aboutActive from '@/assets/tabbar/icon_aboutus_yellow.png'
import aboutInactive from '@/assets/tabbar/icon_aboutus_gray.png'

export default {
    name: 'App',
    components: {
        Footer,
        Header
    },
    data() {
        return {
            active:0,
            icon: {
                indexActive: indexActive,
                indexInactive: indexInactive,
                unionActive: unionActive,
                unionInactive: unionInactive,
                sytemActive: sytemActive,
                systemInactive: systemInactive,
                cooperateActive: cooperateActive,
                cooperateInactive: cooperateInactive,
                aboutActive: aboutActive,
                aboutInactive: aboutInactive

            },
        }

},
    created() {
    }
}
</script>

<style lang="less" scoped>
#app {
    background-color: white;
    height: auto;
    margin-bottom: 100px;
    overflow-x: hidden;
}

.van-tabbar {
    font-size: 1.08rem;
    height: 40px;
    padding: 0.1rem 0;

    #tab-text {

    }
}

[class*=van-hairline]::after {
    border: none
}
</style>
