<template>
    <div>
        <div class="system-container">
            <div class="head">
                <img class="head_img" src="./images/img_banner_system.jpg" alt="">
                <div class="title">
                    云卡平台
                </div>
                <div class="line-box">
                    <div class="line"></div>
                </div>
            </div>
            <div class="body">
                <div class="item-box">
                    <div class="item">
                        <img class="img_box" src="./images/img_platform01.jpg" alt="">
                        <div class="intro">
                            <div class="intro-box">
                                <div class="intro-number">01</div>
                                <div class="intro-text">物流公司车队管理</div>
                            </div>
                        </div>
                    </div>
                    <div class="item-con">
                        物流公司实现车队全面管理的SaaS平台，包括维修管理、车辆管理、人员管理、动力管理、车队成本管理。
                    </div>
                </div>
                <div class="item-box">
                    <div class="item">
                        <img class="img_box" src="./images/img_platform02.jpg" alt="">
                        <div class="intro">
                            <div class="intro-box">
                                <div class="intro-number">02</div>
                                <div class="intro-text">配件电商平台</div>
                            </div>
                        </div>
                    </div>
                    <div class="item-con">
                        物流公司实现车队全面管理的SaaS平台，包括维修管理、车辆管理、人员管理、动力管理、车队成本管理。
                    </div>
                </div>
                <div class="item-box">
                    <div class="item">
                        <img class="img_box" src="./images/img_platform03.jpg" alt="">
                        <div class="intro">
                            <div class="intro-box">
                                <div class="intro-number">03</div>
                                <div class="intro-text">门店管理系统SaaS</div>
                            </div>
                        </div>
                    </div>
                    <div class="item-con">
                        帮助维修企业实现系统化，标准化管理，涉及财务管理、库存管理、采购管理、维修管理、人员管理、网络营销、客户管理，利用云卡平台的优势提高业务收入，经营效率。
                    </div>
                </div>
                <div class="item-box">
                    <div class="item">
                        <img class="img_box" src="./images/img_platform04.jpg" alt="">
                        <div class="intro">
                            <div class="intro-box">
                                <div class="intro-number">04</div>
                                <div class="intro-text">个体车主</div>
                            </div>
                        </div>
                    </div>
                    <div class="item-con">
                        为3000万货运卡车司机提供物流运输过程中所有相关服务对接。涉及维修保养、轮胎服务、道路救援、保险、事故车维修，加油、ETC，安全驾驶、二手车、线路推荐等等。
                    </div>
                </div>
                <div class="item-box">
                    <div class="item">
                        <img class="img_box" src="./images/img_platform05.jpg" alt="">
                        <div class="intro">
                            <div class="intro-box">
                                <div class="intro-number">05</div>
                                <div class="intro-text">金融平台</div>
                            </div>
                        </div>
                    </div>
                    <div class="item-con">
                        通过云卡大数据平台为金融机构和配件经销商、维修企业提供配件采购供应链金融服务；为车队和个体车主客户提供消费金融服务。
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {wxInitGet} from "../../interface/api/wxInit";

    export default {
        name: "index",
        created() {
            this.wxInit()
        },
        methods:{
            wxInit() {
                const data = {
                    url: 'http://h5.www.yuntruck.com/system'
                }

                wxInitGet(data).then(res => {
                    let info = res.response

                    // 需要使用的JS接口列表
                    const wxJsApiList = [
                        'checkJsApi',
                        'updateAppMessageShareData',
                        'updateTimelineShareData',
                        'onMenuShareTimeline',
                        'onMenuShareAppMessage'
                    ];
                    wx.config({
                        debug: false,
                        appId: info.appId,
                        timestamp: info.timestamp,
                        nonceStr: info.nonceStr,
                        signature: info.signature,
                        jsApiList: wxJsApiList
                    });

                    wx.error(function (res) {
                        //console.log('errInfo:',res)
                        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
                    });
                    let  infoUrl =  `${window.location.protocol}//${window.location.host}/redirect.html?redirectUrl=${encodeURIComponent(info.url)}`;
                    var shareAppMessage = {
                        title: "云卡科技官网",
                        desc: "商用车维修连锁服务互联网平台",
                        link: infoUrl,
                        imgUrl: "http://images.yuntruck.com/default/202303/1678439131573.png",
                        success() {
                            //console.log("分享成功")
                        }
                    };

                    wx.ready(function () {
                        wx.checkJsApi({
                            jsApiList: wxJsApiList,
                            success(res) {
                                //console.log("checkJsApi: " + JSON.stringify(res));
                            }
                        });
                        wx.updateAppMessageShareData(shareAppMessage)
                        wx.updateTimelineShareData(shareAppMessage)
                    })

                }).catch((error => {
                    console.log('error:', error)
                }))

            },
        }
    }
</script>

<style scoped lang="less">
    .system-container {
        .head {
            .head_img {
                width: 31.25rem;
                height: 16.67rem
            }

            .title {
                margin-top: 0.58rem;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #555555;
                font-weight: bold;
                font-size: 1.5rem;
            }

            .line-box {
                margin-bottom: 1.92rem;
                margin-top: 0.75rem;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .line {
                    width: 3.33rem;
                    height: 0.17rem;
                    background-color: #CCCCCC;

                }
            }
        }

        .body {
            .item-box{
                margin: 0 1.67rem;
            .item {
                position: relative;

                .img_box {
                    width: 27.92rem;
                    height: 13rem;
                }

                .intro {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 27.93rem;
                    height: 3.33rem;
                    background: #444444;

                    .intro-box {
                        height: 3.33rem;
                        display: flex;
                        align-items: center;

                        .intro-number {
                            float: left;
                            font-size: 6rem;
                            margin-top: -2.5rem;
                            margin-left: 0.58rem;
                            color: #ffffff;
                            opacity: 0.3;
                        }

                        .intro-text {
                            margin-left: 1.67rem;
                            font-size: 1.5rem;
                            font-weight: bold;
                            color: #EE7B23;

                        }
                    }
                }


            }
                .item-con{
                 margin:1.08rem 0;
                    font-size: 1.25rem;
                    color: #666666;
                }
            }
        }


    }
</style>
