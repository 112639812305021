<template>
	<div class="mallproduct-payload">
		
		<div class="psearch">
			<van-sticky>
				<van-search 
					v-model="search.kws" 
					placeholder="请输入搜索关键词" 
					@search="onSearch"
				/>
			</van-sticky>
		</div>

		<div class="pitem">
			<van-row>
				<van-col :span="4">
					<div class="pitem-left">
						<van-sidebar v-model="querys.tabIdx">
							<template v-for="(item,idx) in categoryItem">
								<van-sidebar-item 
									:title="item.name"
									@click="handleClickCategory(idx)"
								/>
							</template>							
						</van-sidebar>
					</div>
				</van-col>

				<van-col :span="20">
					<div v-if="pageItem.length > 0" class="pitem-right">
						<van-list
							v-model="querys.loading"
							:finished="querys.finished"
							offset="50"
							finished-text="没有更多数据了！"
							@load="onNextPage"
						>
							<template v-for="(item,idx) in pageItem" >
								<van-cell>
									<template slot="icon">
										<van-image
											class="pimage"
											width="60"
											height="60"
											:src="item.productImage"
										/>
									</template>
									<template slot="title">
										{{item.productName}}
									</template>
									<template slot="label">
										<div class="row">{{item.skuLabel}}</div>
										<div class="row money">
											<span class="price">零售价:<span class="num">￥{{formatMoney(item.priceSale)}}</span>
											</span>
											<span class="price">券后价:<span class="num">￥{{formatMoney(item.priceCoupon)}}</span>
											</span>
										</div>
									</template>
									<template slot="right-icon">
										<div class="pright">
											<!-- <div>
												<van-icon 
													v-if="idx == 0"
													class="selected" 
													name="circle" 
													size="20px"
													color="#909399"
												/>
													<van-icon 
													v-if="idx == 1"
													class="selected" 
													name="passed" 
													size="20px" 
													color="#67C23A"
												/>
											</div> -->

											<van-tag 
												class="send"
												type="primary"
												size="medium"
												@click="handleSend(idx)"
											> 发送 </van-tag>

										</div>
									</template>
								</van-cell>
							</template>
						</van-list>
					</div>

					<div v-else class="pitem-right">
						<div v-if="querys.loading" class="load">
							<van-loading 
								size="36px"
								type="spinner"
							/>
						</div>
						<van-empty v-else description="暂无查询商品!" />
					</div>
				</van-col>
			</van-row>
		</div>

		<!--分享弹框-->
		<van-popup 
			v-model="send.show" 
			position="bottom" 
			:style="{ height: '60vh' }" 

		>
			<div class="send-payload">
				<div class="title">请选择发送方式</div>
				<div class="content">
					<van-row>
						<van-col :span="12">
							<div class="thalf">
								<div class="stext">
									<div class="stitle">{{send.text.title}}</div>
									<div class="slink">{{send.text.url}}</div>
								</div>
							</div>
						</van-col>
						<van-col :span="12">
							<div class="thalf">
								<div class="tmini">
									<div class="ttitle">{{send.mini.title}}</div>
									
									<van-image
										class="timage"
										width="150"
										height="150"
										:src="send.mini.image"
									/>
								</div>
							</div>
						</van-col>
					</van-row>
				</div>
				<van-tabbar v-model="send.type">
					<van-tabbar-item icon="link-o" @click="handlClickShare('text')">发送文字链接</van-tabbar-item>
					<van-tabbar-item icon="miniprogram-o" @click="handlClickShare('mini')">发送小程序</van-tabbar-item>
				</van-tabbar>
			</div>
		</van-popup>
	</div>
</template>
<script type="text/javascript">
import {getCategory,getSkus} from '@/interface/oms/pmall/product/product';
export default {
	data(){
		return {
			querys:{
				tabIdx: '',
				loading: false,
				finished: true
			},
			send:{
				type: '',
				show: false,
				currentIdx: '',
				text:{
					title: '',
					url: ''
				},
				mini:{
					title: '',
					image: ''
				}	
			},
			categoryItem:[],
			search:{
				kws: '',
				page: 1,
				psize: 10,
				orby: '',
				sort: '',
				categoryId: ''
			},
			pageItem:[]
		}
	},
	created: function() {
		this.getCategory();
		this.onSearch();
	},
	methods:{
		handleSend: function(idx){
			let current = this.pageItem[idx];

			let title = current.productName+' '+current.skuLabel+' ('+current.priceSale+'元)';

			this.send.text = {
				title: title,
				url: '分享短连接',
			}
			this.send.mini = {
				title: title,
				image: current.productImage,
				url: '/pages/mall/mshop/pdetail/pdetail?productId='+current.productId+'&skuId='+current.skuId+'&thirdCode=qywx'
			}

			this.send.currentIdx = idx;
			this.send.show = true;
		},
		handlClickShare: function(type){
			this.$toast('分享功能开发中,请等待...!');
		},
		getCategory: function(){
			getCategory()
				.then(res=>{
					if( res.status && res.response.length > 0 ){
						this.categoryItem = res.response;
					}

					let row = {id:0, name: '全部'};
					this.categoryItem.unshift(row);
				})
				.catch(err=>{})
		},
		handleClickCategory: function(idx){
			let current = this.categoryItem[idx];
			this.search.categoryId = current.id;

			this.onSearch();
		},
		onSearch: function(){
			this.search.page = 1;
			this.pageItem = [];

			this.getPageData(this.search);
		},
		onNextPage: function(){
			this.search.page = this.search.page + 1;

			this.getPageData(this.search);
		},
		getPageData: function(search){
			this.querys.loading = true;
			getSkus(search)
				.then(res=>{
					if( res.status && ('item' in res.response) ){

						if( res.response.item.length > 0 ){
							let _this = this;
							res.response.item.forEach( function(item){
								let row = {
									productId: item.productId,
									skuId: item.skuId,
									productName: item.productName,
									productImage: item.productImage,
									remark: item.remark,
									type: item.type,
									skuLabel: item.skuLabel.join('/'),
									priceSale: item.priceSale,
									priceCoupon: item.priceCoupon
								}

								_this.pageItem.push(row);
							})
						}

						this.querys.finished = this.pageItem.length >= res.response.total;
					}

					this.querys.loading = false;
				})
				.catch(err=>{})
		},
		formatMoney: function(money){
			return parseFloat(money).toFixed(2);
		}
	}
}
</script>