<template>
	<div class="insure-ucenter">
		<div class="ucenter-info">
			<van-row>
				<van-col :span="6">
					<van-image class="photo" 
						src="http://images.yuntruck.com/default/202412/1734578582964.png"
						width="50"
						height="50"
						round
					/>
				</van-col>
				<van-col :span="16">
					<div class="uinfo">
						<div class="uname">{{uinfo.uname}}</div>
						<div class="tel">{{uinfo.tel}}</div>
					</div>
				</van-col>
			</van-row>
		</div>

		<div class="ucenter-item">
			<div class="payload">
				<van-cell
					title="服务经理"
					value="姜经理(13810596535)"
				>
				</van-cell>
			</div>

			<div class="loginOut">
				<van-button 
					type="info" 
					@click="handleLoginOut"
					size="middle"
					class="btn"
				>退出登录</van-button>
			</div>
		</div>

		<Tabbar />
	</div>
</template>
<script type="text/javascript">
import Tabbar from '@/pages/insure/tabbar';
import {getToken,getInsureInfo,clearToken} from '@/util/token';

export default {
	components:{
		Tabbar,
	},
	data(){
		return {
			uinfo:{
				uname: '',
				tel: ''
			}
		}
	},
	created: function() {
		let token = getToken();
		if( !('token' in token) || token.token == '' || token.token == null){
			this.$router.push('/insure');
		}

		let insureInfo = getInsureInfo();
		this.uinfo = {
			uname: insureInfo.uname,
			tel: insureInfo.tel
		}
	},
	methods:{
		handleLoginOut: function(){
			this.$dialog.confirm({
				title: '退出登录',
				message: '您确定要退出登录吗?',
				confirmButtonText: '确认',
				cancelButtonText: '再想想!'
			}).then(()=>{
				clearToken();
				this.$router.push('/insure');
			}).catch(()=>{})
		}
	}
}
</script>