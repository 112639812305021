<template>
	<div class="insure-form">
		<div class="form-uptemp">
			<van-row>
				<van-col :span="12">
					<div class="upbg" @click="handlePreimage('template')">
						<div class="temp">
							<template v-for="item in [1,2,3,4,5,6,7,8,9,10,11,12]">
								<span>样板</span>
							</template>
						</div>

						<van-image class="img" :src="querys.preImage" />
						<span style="margin-right:10px;"></span>
						<van-image class="img" :src="querys.preImage1" />
					</div>
				</van-col>

				<van-col :span="12">
					<div class="upbg">
						<van-image 
							class="img" 
							:src="form.attach" 
						>
							<van-uploader
								:after-read="handleUpload"
								class="vuploader"
							/>
						</van-image>
					</div>
				</van-col>

				<!--图片预览组件-->
				<van-image-preview 
					v-model="preImage.show" 
					showIndicators
					closeable
					:images="preImage.images"
				>
				</van-image-preview>
			</van-row>

			<div class="tip" @click="handlePreimage('template')">
				<van-icon name="warning-o" /> 请按照保单(<span class="model">样板</span>)上传图片,两种模板任意一个即可!
			</div>
		</div>

		<div class="form-warp">
			<van-form ref="form" :rules="formRules">

				<div class="gtitle">案件信息</div>
				<van-cell-group>
					<van-field 
						label="报案号"
						v-model="form.insureCode" 
						placeholder="请输入保司报案号" 
						required
						size="large"
						:rules="formRules.insureCode"
					/>

					<van-field 
						label="车辆牌照"
						v-model="form.idCard" 
						placeholder="请输入车辆牌照"
						required 
						size="large"
						:rules="formRules.idCard"
					>
					</van-field>

					<van-field
						required
						readonly
						is-link
						size="large"
						v-model="form.shopName"
						label="维修门店"
						placeholder="请选择推送门店"
						@click="handleTriggerShop"
						:rules="formRules.shopId"
					/>

					<van-field 
						label="出险地点"
						v-model="form.address" 
						placeholder="请输入车辆出险地点"
						required
						size="large"
						:rules="formRules.address"
					/>
				</van-cell-group>

				<div class="gtitle">人员信息</div>
				<van-cell-group>
					<van-field 
						label="查勘员"
						v-model="form.checker" 
						placeholder="请输入查勘员姓名" 
						required
						size="large"
						:rules="formRules.checker"
					/>

					<van-field 
						label="查勘员手机"
						v-model="form.checkerTel" 
						placeholder="请输入查勘员手机"
						required 
						size="large"
						:rules="formRules.checkerTel"
					>
					</van-field>

					<van-field 
						label="报案人"
						v-model="form.apply" 
						placeholder="请输入报案人姓名"
						required
						size="large"
						:rules="formRules.apply"
					/>

					<van-field 
						label="报案人手机"
						v-model="form.applyTel" 
						placeholder="请输入报案人手机"
						required
						size="large"
						:rules="formRules.applyTel"
					/>
				</van-cell-group>


				<div class="gtitle">备注信息</div>
				<van-cell-group>
					<van-field 
						type="textarea"
						v-model="form.note" 
						placeholder="请输入备注信息" 
						rows="5"
    					autosize
    					size="large"
					/>
				</van-cell-group>

			</van-form>
		</div>

		<div class="sub-warp">
			<van-button 
				type="info" 
				@click="handleSubmit"
				size="middle"
				class="btn"
			>确认提交信息</van-button>
		</div>

		<!--门店选择-->
		<van-popup 
			v-model="querys.shopShow"
			position="bottom"
			:style="{ height: '80%' }"
		>
			<div class="filter-payload">
				<div class="filter-search">
					<van-row>
						<van-col :span="4">
							<div class="location" @click="handleTriggerLocation">
								<van-icon class="vicon" name="location-o" />
								<span class="position">{{location.position}}</span>
							</div>
						</van-col>
						<van-col :span="20">
							<van-search
								v-model="shopSearch.kws"
								placeholder="请输入搜索关键词"
								@blur="onShopSearch"
							/>
						</van-col>
					</van-row>
				</div>

				<!--选择门店-->
				<div v-if="shopPageItem.length > 0" class="filter-payload">
					<template v-for="(item,idx) in shopPageItem">
						<van-cell @click="handleClickShop(idx)">
							<template slot="title">
								<span class="title">{{item.name}}</span>
							</template>
							<template slot="label">
								{{item.provName}}·{{item.cityName}}·{{item.areaName}} {{item.address}}
							</template>

							<van-radio slot="right-icon" name=""/>
						</van-cell>
					</template>
				</div>
				<div v-else class="filter-payload">
					<van-empty description="当前区域未开放门店,请联系管理!" />
				</div>
			</div>
		</van-popup>

		<!--位置选择-->
		<van-popup 
			v-model="querys.locationShow"
			position="bottom"
			:style="{ height: '90%' }"
		>
			<div class="filter-payload">
				<div class="filter-search">
					<van-row>
						<van-col :span="20">
							<van-search
								v-model="location.kws"
								placeholder="请输入搜索关键词"
								@blur="onLocationSearch"
							/>
						</van-col>
						<van-col :span="4">
							<van-button 
								class="clear"
								size="small"
								@click="handleClearLocation"
							>清除</van-button>
						</van-col>
					</van-row>
				</div>

				<!--选择位置-->
				<div v-if="location.data.length > 0" class="filter-payload">
					<van-index-bar :index-list="location.indexs">
						<template v-for="(item,idx) in location.data">
							<van-index-anchor 
								:index="item.label"
							>{{item.label}}</van-index-anchor>
							
							<template v-for="(lt, lidx) in item.items">
								<van-cell :title="lt.name" 
									@click="handleClickLocation(idx, lidx)"
								/>
							</template>
							
						</template>
					</van-index-bar>
				</div>
				<div v-else class="filter-payload">
					<van-empty description="仅支持查询省市,不支持地区查询" />
				</div>
			</div>
		</van-popup>

		<Tabbar />
	</div>
</template>
<script type="text/javascript">
import Tabbar from '@/pages/insure/tabbar';

import {apply} from '@/interface/insure/fixorder';
import {get as getShop,defShop} from '@/interface/insure/shop';
import {initText} from '@/interface/public/ocr';
import {imageContentStr} from '@/interface/public/alioss';
import {getAreas} from '@/interface/public/areas';

import {getToken,getInsureInfo} from '@/util/token';
export default {
	components:{
		Tabbar
	},
	data(){
		return {
			isSubmit: false,
			querys:{
				preImage: 'http://images.yuntruck.com/default/202412/1733813193776.png',
				preImage1: 'http://images.yuntruck.com/default/202412/1735180054532.png',
				fileList: [],
				shopShow: false,
				locationShow: false,
				loading: false,
				finished: false,
			},
			preImage:{
				show: false,
				images: []
			},
			location:{
				kws: '',
				position:'位置',
				indexs:[],
				data:[]
			},
			areaItem: [],
			form:{
				insureCode: '',
				idCard: '',
				shopId: '',
				shopName: '',
				address: '',
				checker: '',
				checkerTel: '',
				apply: '',
				applyTel: '',
				note: '',
				attach: '',
				params: '',
			},
			formRules:{
				insureCode: [
					{ required: true, message: '请输入保司报案号' },
					{ pattern: /^.{5,30}$/, message: '保司报案号最少5个字符', trigger:'onChange'},
				],
				idCard: [
					{ required: true, message: '请输入车辆牌照' },
					{ pattern: /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$)|([A-Z0-9]{5}[DF]$)|([DF][A-Z0-9]{5}$))/, message: '请输入正确的车辆牌照！', trigger:'onChange'},
				],
				shopId: [
					{ required: true, message: '请选择推送门店' },
				],
				address: [
					{ required: true, message: '请输入车辆出险地点' },
				],
				checker: [
					{ required: true, message: '请输入查勘员姓名' },
					{ pattern: /^.{2,30}$/, message: '姓名最少2个字符', trigger:'onBlur'},
				],
				checkerTel: [
					{ required: true, message: '请输入查勘员手机' },
					{ pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的查勘员手机号'},
				],
				apply: [
					{ required: true, message: '请输入报案人姓名' },
					{ pattern: /^.{2,30}$/, message: '姓名最少2个字符', trigger:'onBlur'},
				],
				applyTel: [
					{ required: true, message: '请输入报案人手机号' },
					{ pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的报案人手机号'},
				],
			},
			shopSearch:{
				page: 1,
				psize: 1000,
				kws: '',
				provId: '',
				cityId: '',
				areaId: '',
			},
			shopPageItem: [],
		}
	},
	created: function() {
		let token = getToken();
		if( !('token' in token) || token.token == '' || token.token == null){
			this.$router.push('/insure');
		}

		let insureInfo = getInsureInfo();
		this.form.checker = insureInfo.uname;
		this.form.checkerTel = insureInfo.tel;
		this.location.position = insureInfo.locationName;

		this.shopSearch.provId = insureInfo.provId;
		this.shopSearch.cityId = insureInfo.cityId;

		this.onShopSearch();
	},
	methods:{
		handlePreimage: function(type){
			if( type == 'template' ){
				this.preImage.images = [this.querys.preImage, this.querys.preImage1];
			}else if( type == 'attach' ){
				this.preImage.images = [this.form.attach];
			}

			this.preImage.show   = true;
		},
		handleCloseImage: function(){
			this.preImage.images = [];
			this.preImage.show   = false;
		},
		handleTriggerLocation: function(){
			this.querys.locationShow = !this.querys.locationShow;
			if( this.querys.locationShow && this.areaItem.length <= 0){
				this.getAreas();
			}
		},
		handleTriggerShop: function(){
			this.querys.shopShow = !this.querys.shopShow;
		},
		handleClickLocation: function(idx, lidx){
			let current = this.location.data[idx].items[lidx];
				this.location.position = current.name;

			if( current.suffix == '省' ){
				this.shopSearch.provId = current.value;
				this.shopSearch.cityId = 0;
				this.shopSearch.areaId = 0;
			}else if( current.suffix == '市' ){
				this.shopSearch.provId = 0;
				this.shopSearch.cityId = current.value;
				this.shopSearch.areaId = 0;
			}else if( current.suffix == '区' || current.suffix == '县' ){
				this.shopSearch.provId = 0;
				this.shopSearch.cityId = 0;
				this.shopSearch.areaId = current.value;
			}else{
				this.shopSearch.provId = 0;
				this.shopSearch.cityId = 0;
				this.shopSearch.areaId = 0;
			}
			this.onShopSearch();

			this.handleTriggerLocation();
		},
		handleClearLocation: function(){
			this.location.position = '位置';
			this.location.kws = '';

			this.shopSearch.provId = 0;
			this.shopSearch.cityId = 0;
			this.shopSearch.areaId = 0;

			this.onShopSearch();
			this.handleTriggerLocation();

			this.formatArea(JSON.parse(JSON.stringify(this.areaItem)) );
		},
		onLocationSearch: function(){
			let kws = this.location.kws;
			let data = [];

			this.areaItem.forEach( function(item){
				if( item.name.indexOf(kws) > -1 ){
					data.push(item);
				}
			})

			this.formatArea(data);
		},
		getAreas: function(){
			getAreas()
				.then(res=>{
					if( res.status && res.response.length > 0 ){
						this.areaItem = res.response;
						this.formatArea(JSON.parse(JSON.stringify(this.areaItem)) );
					}
				})
				.catch(err=>{})
		},
		formatArea: function(data){
			let datas  = {};

			data.forEach( function(item){
				if( item.parent != 50000 && item.initial != ''){
					let key = item.initial.toUpperCase();

					if( !(key in datas) ){
						datas[key] = {
							label: key,
							items: []
						}
					}

					datas[key].items.push(item);
				}
			})

			let indexs = Object.keys(datas).sort();
			let dataSort = [];
			indexs.forEach( function(idx){
				if( idx in datas ){
					dataSort.push(datas[idx]);
				}
			})

			this.location.indexs = indexs;
			this.location.data 	= dataSort;
		},
		handleSubmit: function(){
			if( this.isSubmit ) return false;
			this.isSubmit = true;

			this.$refs.form.validate()
				.then(() => {
					apply( this.form )
						.then( res=>{
							if(res.status && ('orderId' in res.response)){
								this.$toast('报修工单已提交');
								this.$router.push('/insure/baoxiu');
							}else{
								this.$toast(res.msg);
							}

							this.isSubmit = false;
						})
						.catch(err=>{
							this.isSubmit = false;
						})
				})
				.catch(() => {
					this.isSubmit = false;
				});
		},
		handleClickShop: function(idx){
			let current = this.shopPageItem[idx];
			this.form.shopId   = current.id;
			this.form.shopName = current.name;

			this.handleTriggerShop();
		},
		onShopSearch: function(){
			this.shopSearch.page = 1;
			this.shopPageItem = [];

			this.getShopPageData( this.shopSearch );
		},
		onShopNextPage: function(){
			this.shopSearch.page++;

			this.getShopPageData( this.shopSearch );
		},
		getShopPageData: function(search){
			this.querys.loading = true;
			getShop(search)
				.then(res=>{
					if( res.status && ('items' in res.response) ){
						let _this = this;
						res.response.items.forEach( function(item){
							_this.shopPageItem.push(item);
						})
					}

					//追加一条默认门店(上海卡联门店)
					this.getDefShop();

					this.querys.finished = this.shopPageItem.length >= res.response.total;
					this.querys.loading = false;
				})
				.catch(err=>{
					this.querys.loading = false;
				})
		},
		getDefShop: function(){
			defShop()
				.then(res=>{
					if( ('id' in res.response) ){
						let info = res.response;
						let row  = {
							id: info.id,
							name: info.name,
							provName: info.provName,
							cityName: info.cityName,
							areaName: info.areaName,
						}
						this.shopPageItem.push(row);
					}
				})
				.catch(err=>{})
		},
		/**
		 *图片上传
		 */
		handleUpload: function(file){
			if( !('file' in file) ){
				this.$toast('图片上传失败,请稍后再试!');
				return false;
			}
			
			let pos 	= file.content.indexOf('base64');
			let type  	= file.file.type.split('/');
			let extension = type[1];

			let data  = {
				filename : 'uploaderImg.'+extension,
				scene: 'insureReport',
				size: file.file.size,
				content: file.content.substr(pos + 7),
			}

			imageContentStr(data)
				.then(res=>{					
					if( res.status && ('fullPath' in res.response) ){
						let path = res.response.fullPath;
						this.imageOcr( path );
						this.form.attach = path;
					}else{
						this.$toast('图片上传失败,请稍后再试!');
					}
				})
				.catch(err=>{})
		},
		/**
		 *上传图片进行识别
		 */
		imageOcr: function(imageUrl){
			let data = {
				recognize: 'recognizeAdvanced',
        		imageUrl: imageUrl
			}
			initText(data)
				.then(res=>{
					if( res.status && ('content' in res.response) ){
						let content = res.response.content;
						if( content.indexOf('商用货车') >= 0 || content.indexOf('统保代码名称') >= 0){
							this.parseString2( content );
						}else{
							this.parseString1( content );
						}						
					}else{
						this.$toast('请参考样板,上传正确的保单图片!');
					}
				})
				.catch(err=>{})
		},
		/**
		*解析表单字符串(模板一)
		*
		*注：从后往前解析数据，识别程度稍微高一些
		*/
		parseString1: function(str){
			let ocrContent = {
				key: 'ocr', 
				label: '识别结果', 
				val: str
			};

			//ocr解析字段配置
			let ocrCfg = [
				{key:'insureCode', label:'报案号', val:''},
				{key:'idCard', label:'车', val:''},
				{key:'shopName', label:'修理厂名称', val:''},
				{key:'apply', label:'报案人', val:''},
				{key:'insured', label:'被保险人', val:''},
				{key:'insureType', label:'报案类型', val:''},
				{key:'channel', label:'报案渠道', val:''},
				{key:'accidentTime', label:'出险时间', val:''},
				{key:'checkCompany', label:'查勘分公司', val:''},
				{key:'insureCompany', label:'承保分公司', val:''},
				{key:'issuedTime', label:'案件下发时间', val:''},
				{key:'checkAddr', label:'查勘地址', val:''},
				{key:'applyTel', label:'报案人手机号', val:''},
				{key:'dispatchTime', label:'调度时间', val:''},
				{key:'insureTime', label:'整案标记', val:''},
				{key:'checker', label:'查勘员姓名', val:''},
				{key:'checkerTel', label:'查勘员电话', val:''},
				{key:'address', label:'查勘区域', val:''},
			];

			let fieldItem = JSON.parse(JSON.stringify(ocrCfg));
			    fieldItem = fieldItem.reverse();

			const regexInsureCode = /[A-Za-z0-9]+/;
			const regexTel = /1\d{10}/;
			const regexIdCard = /[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽赣贵粤青藏川宁琼使领][A-Z][A-Z0-9]{4}[A-Z0-9]/;

			fieldItem.forEach( function(item){
			    let pos = str.indexOf(item.label);
			    if( pos >= 0 ){
			        let len = item.label.length;
			        let val = str.substr(pos + len + 1).trim();
			        
			        //匹配手机
			        if( item.key == 'applyTel' || item.key == 'checkerTel'){
			            //提取手机号
			            item.val = val.match(regexTel) ? val.match(regexTel)[0] : val;
			        }else if( item.key == 'idCard' ){
			            //提取车辆牌照
			            item.val = val.match(regexIdCard) ? val.match(regexIdCard)[0] : val;
			        }else if( item.key == 'insureCode'){
			            //提取报案号
			            item.val = val.match(regexInsureCode) ? val.match(regexInsureCode)[0] : val;
			        }else{
			            item.val = val;
			        }
			        
			        str = str.substr(0, pos);
			    }
			})

			fieldItem = fieldItem.reverse();
			fieldItem.push(ocrContent);

			//将识别的字段设置到表单字段中
			let formField = ['insureCode', 'address', 'idCard', 'apply', 'applyTel'];
			let _this = this;
			fieldItem.forEach(function(item){
			    if( formField.includes(item.key) ){
			        _this.form[item.key] = item.val;
			    }
			})

			this.form.params = JSON.stringify(fieldItem);
		},
		/**
		*解析表单字符串(模板二)
		*
		*注：从后往前解析数据，识别程度稍微高一些
		*/
		parseString2: function(str){
			let ocrContent = {
				key: 'ocr', 
				label: '识别结果', 
				val: str
			};

			//ocr解析字段配置
			let ocrCfg = [
				{key:'insureCode', label:'报案号', val:''},
				{key:'idCard', label:'车辆牌照', val:''},
				{key:'address', label:'出险地点', val:''},
				{key:'note', label:'商用货车', val:''},
				{key:'apply', label:'报案人', val:''},
				{key:'applyTel', label:'报案人手机号', val:''},
				{key:'driver', label:'驾驶员', val:''},
				{key:'driverTel', label:'驾驶员手机', val:''},
				{key:'issuedTime', label:'报案时间', val:''},
				{key:'dealTime', label:'出险时间', val:''},
				{key:'content', label:'出险次数', val:''}
			];

			let fieldItem = JSON.parse(JSON.stringify(ocrCfg));
			    fieldItem = fieldItem.reverse();

			const regexInsureCode = /[A-Z0-9]+/g;
			const regexTel = /1\d{10}/;
			const regexIdCard = /[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽赣贵粤青藏川宁琼使领][A-Z][A-Z0-9]{4}[A-Z0-9]/;
			
			let idCard 	  = '';
			let applyTel = '';
			let driverTel = '';

			fieldItem.forEach( function(item){
				//这几个字段混合在其他字段中
				if( ['idCard','applyTel','driverTel'].includes(item.key) ){
					return false;
				}

			    let pos = str.indexOf(item.label);
			    if( pos >= 0 ){
			        let len = item.label.length;
			        let val = str.substr(pos + len + 1).trim();
			        
					//出险次数
					if( item.key == 'content' ){
						item.val = val;

						//从匹配字符串中提取车辆牌照
						idCard = val.match(regexIdCard) ? val.match(regexIdCard)[0] : val;
					}else if( item.key == 'apply' ){
						//报案人
						applyTel = val.match(regexTel) ? val.match(regexTel)[0] : val;

						if( applyTel != '' ){
							let p = val.indexOf(applyTel);
							item.val = val.substr(0, p).trim();
						}else{
							item.val = val;
						}
					}else if( item.key == 'driver' ){
						//驾驶员
						driverTel = val.match(regexTel) ? val.match(regexTel)[0] : val;

						if( driverTel != '' ){
							let p = val.indexOf(driverTel);
							item.val = val.substr(0, p).trim();
						}else{
							item.val = val;
						}
					}else if( item.key == 'insureCode' ){
						//提取报案号
						let matchs = val.match(regexInsureCode);
						let code   = '';
						let len    = 0;
						if( matchs ){
							matchs.forEach(function(v){
								if( v.length > len ){
									code = v;
									len  = v.length;
								}
							})
						}
						item.val = code != '' ? code : val;
					}else{
						item.val = val;
					}

			        str = str.substr(0, pos);
			    }
			})

			fieldItem = fieldItem.reverse();
			fieldItem.push(ocrContent);

			//将识别的字段设置到表单字段中
			let formField = ['insureCode', 'address', 'idCard', 'apply', 'applyTel'];
			let _this = this;
			fieldItem.forEach(function(item){
				if( item.key == 'idCard' ){
					item.val = idCard;
				}else if( item.key == 'applyTel'){
					item.val = applyTel;
				}else if( item.key == 'driverTel' ){
					item.val = driverTel;
				}

			    if( formField.includes(item.key) ){
			        _this.form[item.key] = item.val;
			    }
			})

			this.form.params = JSON.stringify(fieldItem);
		},
	}
}
</script>