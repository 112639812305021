import api from '@/interface/baseApi'

//合同中已接收的门店
export function get(params) {
    return api({
        url: '/insure/shop/get',
        method: 'get',
        params:{
        	page: ('page' in params) ? params.page : 1,
			psize: ('psize' in params) ? params.psize : 20,
			kws: ('kws' in params) ? params.kws : '',
			provId: ('provId' in params) ? params.provId : '',
			cityId: ('cityId' in params) ? params.cityId : '',
			areaId: ('areaId' in params) ? params.areaId : '',
        }
    })
}

//默认卡联门店
export function defShop(params) {
    return api({
        url: '/insure/shop/defShop',
        method: 'get',
        params:{}
    })
}