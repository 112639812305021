import api from '@/interface/baseApi'

//图片上传
export function imageContentStr(params) {
    return api({
        url: '/publics/alioss/imageContentStr',
        method: 'post',
        data:{
        	filename: params.filename,
        	scene: params.scene,
        	content: params.content,
        	size: ('size' in params) ? params.size : 0,
        }
    })
}
