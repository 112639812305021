<template>
    <div>
        <div class="union-container">
            <div class="head">
                <img class="img-box" src="./images/bg_profiles.png" alt="">
                <div class="mask">
                    <div class="title-t">
                        云卡联盟
                    </div>
                    <div class="title-m">
                        国内首家O2O卡车维修联盟互联网平台
                    </div>
                    <div class="line-box">
                        <div class="line"></div>
                    </div>
                    <div class="content">
                        云卡联盟是国内首家O2O的卡车维修联盟互联网平台，通过整合线下门店形成连锁联盟服务，建立全国范围内的卡车维修服务体系，与传统商用车修理厂不同的是，我们拥有全品牌、全品类、全国覆盖的能力，为全国商用车客户提供统一的价格，统一服务标准，统一结算的维修保养服务保障。
                    </div>
                </div>
            </div>
            <div class="head-con">
                <img class="img-box" src="./images/bg_title_advantage.png" alt="">
                <div class="mask">
                    <div class="title-t">
                        维修保养服务
                    </div>
                    <div class="content">
                        云卡联盟为客户提供全方位的优质维修保养服务,
                        具有7大优势
                    </div>
                </div>
            </div>
            <div class="body">
                <div class="body-item">

                    <div class="item">
                        <img class="head-img" src="./images/img_title_advantage_01.png" alt="">
                    </div>
                    <div class="title">我们的网络可以服务所有市场主流主机厂品牌的卡车，从国产车到进口车。</div>
                    <div class="home-item-swiper">
                        <van-swipe class="my-swipe" indicator-color="#F08343"  @change="onChange">
                            <van-swipe-item>
                                <img class="swipe_img" src="./images/logo_cars_01.png" alt="">
<!--                                <div class="item-top">-->
<!--                                    <div class="item-box" style="display: flex">-->
<!--                                        <img class="swiper-img" src="./images/img_carbrand_01.png" alt="">-->
<!--                                        <img class="swiper-img" src="./images/img_carbrand_02.png" alt="">-->
<!--                                        <img class="swiper-img" src="./images/img_carbrand_03.png" alt="">-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="item-top">-->
<!--                                    <div class="item-box">-->
<!--                                        <img class="swiper-img" src="./images/img_carbrand_04.png" alt="">-->
<!--                                        <img class="swiper-img" src="./images/img_carbrand_05.png" alt="">-->
<!--                                        <img class="swiper-img" src="./images/img_carbrand_06.png" alt="">-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="item-top">-->
<!--                                    <div class="item-box">-->
<!--                                        <img class="swiper-img" src="./images/img_carbrand_07.png" alt="">-->
<!--                                        <img class="swiper-img" src="./images/img_carbrand_08.png" alt="">-->
<!--                                        <img class="swiper-img" src="./images/img_carbrand_09.png" alt="">-->
<!--                                    </div>-->
<!--                                </div>-->
                            </van-swipe-item>
                            <van-swipe-item>
                                <img class="swipe_img" src="./images/logo_cars_02.png" alt="">
<!--                                <div class="item-top">-->
<!--                                    <div class="item-box">-->
<!--                                        <img class="swiper-img" src="./images/img_carbrand_10.png" alt="">-->
<!--                                        <img class="swiper-img" src="./images/img_carbrand_11.png" alt="">-->
<!--                                        <img class="swiper-img" src="./images/img_carbrand_12.png" alt="">-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="item-top">-->
<!--                                    <div class="item-box">-->
<!--                                        <img class="swiper-img" src="./images/img_carbrand_13.png" alt="">-->
<!--                                        <img class="swiper-img" src="./images/img_carbrand_14.png" alt="">-->
<!--                                        <img class="swiper-img" src="./images/img_carbrand_15.png" alt="">-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="item-top">-->
<!--                                    <div class="item-box">-->
<!--                                        <img class="swiper-img" src="./images/img_carbrand_16.png" alt="">-->
<!--                                        <img class="swiper-img" src="./images/img_carbrand_17.png" alt="">-->
<!--                                        <img class="swiper-img" src="./images/img_carbrand_18.png" alt="">-->
<!--                                    </div>-->
<!--                                </div>-->
                            </van-swipe-item>
                            <van-swipe-item>
                                <img class="swipe_img" src="./images/logo_cars_03.png" alt="">
<!--                                <div class="item-top">-->
<!--                                    <div class="item-box">-->
<!--                                        <img class="swiper-img" src="./images/img_carbrand_19.png" alt="">-->
<!--                                        <img class="swiper-img" src="./images/img_carbrand_20.png" alt="">-->
<!--                                        <div class="swiper-img"></div>-->
<!--                                    </div>-->
<!--                                </div>-->
                            </van-swipe-item>
                            <template #indicator>
                                <!--                        <div class="custom-indicator">{{ current + 1 }}/4</div>-->
                                <div class="van-swipe__indicators">
                                    <div :class="[current==0? activeClass:'',indicator]"></div>
                                    <div :class="[current==1? activeClass:'',indicator]"></div>
                                    <div :class="[current==2? activeClass:'',indicator]"></div>
                                </div>
                            </template>
                        </van-swipe>
                    </div>
                </div>
                <div class="body-item">
                    <div class="item">
                        <img class="head-img" src="./images/img_title_advantage_02.png" alt="">
                    </div>
                    <div class="title">
                        我们的平台将联合各渠道配件厂家，经销商、中心库为我们加盟合作门店提供小到车辆日常保养件（机油，三滤，尿素）大到所有车型件产品供应从而保证为我们物流车辆提供高性价比的配件。
                    </div>
                    <div class="home-category-img">
                        <img class="img" src="./images/img_category.png" alt="">
                    </div>
                </div>
                <div class="body-item">
                    <div class="item-map">
                        <img class="img-box" src="./images/img_map_store.png" alt="">
                        <div class="mask">
                            <div class="mask-item">
                                <img class="mask-head-img" src="./images/img_title_advantage_02.png" alt="">
                            </div>
                            <div class="content">
                                目前云卡维修联盟加盟合作门店已经超过1000家，已经覆盖全国24个省份地区，未来将发展全国5000家门店。
                            </div>
                        </div>
                    </div>
                </div>
                <div class="body-item">
                    <div class="bd-item-box">
                    <div class="item">
                        <img class="head-price-img" src="./images/img_title_advantage_04.png" alt="">
                    </div>
                    <div class="price-title">
                        我们将实现云卡网络联盟内全国统一工时服务价格，统一配件价格， 保证客户在我们任何一家门店享受同样品质的配件和服务价格.
                    </div>
                    </div>
                    <div class="home-price-img">
                        <img class=" price-icon" src="./images/icon_yellow_arrow_down.png" alt="">
                        <img class="img" src="./images/img_uniform_price.jpg" alt="">
                    </div>
                </div>
                <div class="body-item">
                    <div class="bd-item-box">
                    <div class="item">
                        <img style="width: 14.87rem" class="head-price-img" src="./images/img_title_advantage_05.png" alt="">
                    </div>
                    <div class="price-title">
                        我们将利用互联网技术和高科技手段建立我们统一的服务标准体系，保证所有客户在全国享受同样的高质量服务体验。
                    </div>
                    </div>
                    <div class="home-price-img">
                        <img class=" price-icon" src="./images/icon_yellow_arrow_down.png" alt="">
                        <img class="img" src="./images/img_uniform_service.jpg" alt="">
                    </div>
                </div>
                <div class="body-item">
                    <div class="bd-item-box">
                    <div class="item">
                        <img style="width: 14.87rem" class="head-price-img" src="./images/img_title_advantage_06.png" alt="">
                    </div>
                    <div class="price-title">
                        我们也将利用更多线上支付方式为建立统一结算体系 由我们云卡平台实现对所有客户在平台所发生的服务承担后期监督、审核甚至的索赔的责任。
                    </div>
                    </div>
                    <div class="home-price-img">
                        <img class=" price-icon" src="./images/icon_yellow_arrow_down.png" alt="">
                        <img class="img" src="./images/img_uniform_clear.jpg" alt="">
                    </div>
                </div>
                <div class="body-item">
                    <div class="bd-item-box">
                    <div class="item">
                        <img style="width: 14.87rem" class="head-price-img" src="./images/img_title_advantage_07.png" alt="">
                    </div>

                    <div class="price-title">
                        我们将利用互联网技术手段为平台的客户网内更换的配件， 提供全国范围的异地索赔服务，在配件质保期内无论您的车辆跑到哪里都能找到我们合作加盟店完成索赔更换服务。
                    </div>
                    </div>
                    <div class="home-price-img">
                        <img class=" price-icon" src="./images/icon_yellow_arrow_down.png" alt="">
                        <img class="img" src="./images/img_uniform_claim.jpg" alt="">
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {wxInitGet} from "../../interface/api/wxInit";

    export default {
        name: "index",
        data() {
            return {
                current: 0,
                activeClass: 'active',
                indicator: 'indicator',
            }
        },
        created() {
            this.wxInit()
        },
        methods: {
            wxInit() {
                const data = {
                    url: 'https://h5.www.yuntruck.com/union'
                }

                wxInitGet(data).then(res => {
                    let info = res.response

                    // 需要使用的JS接口列表
                    const wxJsApiList = [
                        'updateAppMessageShareData',
                        'updateTimelineShareData',
                        // 'onMenuShareTimeline',
                        // 'onMenuShareAppMessage'
                    ];
                    wx.config({
                        debug: false,
                        appId: info.appId,
                        timestamp: info.timestamp,
                        nonceStr: info.nonceStr,
                        signature: info.signature,
                        jsApiList: wxJsApiList
                    });
                    let  infoUrl =  `${window.location.protocol}//${window.location.host}/redirect.html?redirectUrl=${encodeURIComponent(info.url)}`;
                    wx.error(function (res) {
                        //console.log('errInfo:',res)
                        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
                    });

                    var shareAppMessage = {
                        title: "云卡科技官网",
                        desc: "商用车维修连锁服务互联网平台",
                        link: infoUrl,
                        imgUrl: "http://images.yuntruck.com/default/202303/1678439152179.png",
                        success() {
                            //console.log("分享成功")
                        }
                    };

                    wx.ready(function () {
                        wx.checkJsApi({
                            jsApiList: wxJsApiList,
                            success(res) {
                                //console.log("checkJsApi: " + JSON.stringify(res));
                            }
                        });
                        wx.updateAppMessageShareData(shareAppMessage)
                        wx.updateTimelineShareData(shareAppMessage)
                    })

                }).catch((error => {
                    console.log('error:', error)
                }))

            },
            onChange(index) {
                this.current = index;
            }
        }
    }
</script>

<style scoped lang="less">
    .union-container {
        .head {
            .img-box {
                position: relative;
                width: 31.25rem;
                height: 25rem;
            }

            .mask {
                position: absolute;
                left: 0;
                bottom: 0;
                top: 4.33rem;

                .title-t {
                    margin-top: 2.5rem;
                    width: 31.25rem;
                    text-align: center;
                    font-size: 1.5rem;
                    color: #555555;
                    font-weight: bold;
                }

                .title-m {
                    margin-top: 1rem;
                    text-align: center;
                    font-size: 1.33rem;
                    color: #999999;
                }

                .line-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .line {
                        margin: 1.17rem 0;
                        width: 3.33rem;
                        height: 0.17rem;
                        background-color: #CCCCCC;
                    }
                }

                .content {
                    margin-left: 1.58rem;
                    font-size: 1.25rem;
                    color: #666666;
                    line-height: 2rem;
                }
            }
        }

        .head-con {
            position: relative;
            width: 31.25rem;
            height: 10rem;

            .img-box {
                width: 31.25rem;
                height: 10rem;
            }

            .mask {
                position: absolute;
                left: 0;
                bottom: 0;
                top: 1.67rem;
                color: white;

                .title-t {
                    width: 31.25rem;
                    text-align: center;
                    font-size: 1.5rem;
                    font-weight: bold;
                }

                .content {
                    text-align: center;
                    margin-top: 0.58rem;
                    margin-left: 1.58rem;
                    font-size: 1.33rem;

                }
            }
        }

        .body {
            .body-item {
                .bd-item-box{
                    background-color: #F1F1F1;
                }
                .item {
                    padding-top: 2rem;
                    margin-bottom: 0.75rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .head-img {
                        width: 9.34rem;
                        height: 3.33rem
                    }

                }

                .title {
                    font-size: 1.17rem;
                    color: #666666;
                    text-align: center;
                    line-height: 1.8rem;
                }

                .home-item-swiper {

                      .swipe_img{
                          width:100%;
                          height:auto;

                      }
                    .my-swipe .van-swipe-item {
                        color: #fff;
                        font-size: 20px;
                        text-align: center;
                    }

                    .van-swipe {
                        padding: 2rem 0;
                    }

                    .active {
                        background: #ef803e !important;
                    }

                    .indicator {
                        margin-left: 0.5rem;
                        width: 0.7rem;
                        height: 0.7rem;
                        border-radius: 100%;
                        background: rgba(102, 102, 102, 0.50);
                        -webkit-transition: opacity .2s, background-color .2s;
                        transition: opacity .2s, background-color .2s;
                    }

                    .item-top {
                        width: 27.83rem;
                        height: auto;

                        .item-box {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .swiper-img {
                                width: 8rem;
                                height: 6rem;
                                padding: 1.5rem 0
                            }
                        }


                    }


                }

                .home-category-img {
                    margin-top: 1.08rem;
                    margin-left: 1.67rem;
                    margin-bottom: 1.98rem;

                    .img {
                        width: 27.92rem;
                        height: 8.69rem;
                    }
                }
                .home-price-img{
                    margin-bottom: 0.88rem;
                    position: relative;
                    .img {
                        width: 31.25rem;
                        height:20.12rem;
                    }
                    .price-icon{
                        width: 1.5rem;
                        height: 1.38rem;
                        position: absolute;
                        left: 14.92rem;
                        top:-1rem
                    }

                }
                .head-price-img {
                    width: 11.15rem;
                    height: 3.33rem;

                }
                .price-title {
                  padding: 0.75rem  1.58rem 1.5rem 1.58rem;
                    font-size: 1.17rem;
                    color: #555555;
                    text-align: center;
                    line-height: 1.8rem;
                }
                .item-map {
                    position: relative;
                    width: 31.25rem;
                    height: 33.33rem;

                    .img-box {
                        width: 31.25rem;
                        height: 33.33rem;
                    }

                    .mask {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        top: 1.67rem;
                        color: white;

                        .mask-item {
                            margin-bottom: 0.75rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .mask-head-img {
                                width: 9.34rem;
                                height: 3.33rem
                            }

                        }

                        .content {
                            text-align: center;
                            margin-top: 0.58rem;
                            margin-left: 1.58rem;
                            font-size: 1.33rem;
                            line-height: 1.8rem;

                        }
                    }
                }
            }
        }
    }
</style>
