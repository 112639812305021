<template>
	<div>
		<van-tabs v-model="tabbar" @click="handleTabbar()">
			<template v-for="(tab,idx) in tabItem">
				<van-tab :title="tab.label"></van-tab>
			</template>
		</van-tabs>
	</div>
</template>
<script type="text/javascript">
export default {
	data(){
		return {
			tabbar: 2,
			tabItem:[
				{label:'首页', key:'index', icon: 'comment', url:'/oms'},
				{label:'商城产品', key:'mall', icon: 'comment', url:'/oms/mallproduct'},
				{label:'运营活动', key:'oms', icon: 'comment', url: '/oms/malloms'},
				{label:'特惠车型', key:'oproduct', icon: 'comment', url: '/oms/special'},
			]
		}
	},
	created: function() {
		this.setInit();
	},
	methods:{
		setInit: function(){
			let path = this.$route.path;

			let tabbar = 0;
			this.tabItem.forEach( function(item, idx){
				if( item.url == path ){
					tabbar = idx;
				}
			})

			this.tabbar = tabbar;
		},
		handleTabbar: function(idx){
			let current = this.tabItem[this.tabbar];
				this.$router.push( current.url );
		}
	}
}
</script>