import api from '@/interface/baseApi'

//ocr识别文本内容
export function initText(params) {
    return api({
        url: '/publics/ocr/initText',
        method: 'post',
        data:{
        	recognize: params.recognize,
        	imageUrl: params.imageUrl
        }
    })
}
