<template>
	<div>
		<router-view></router-view>
	</div>
</template>
<script type="text/javascript">
import '@/style/insure.css';
export default {
	data(){
		return {
			
		}
	},
	created: function() {
	},
	methods:{

	}
}
</script>