<template>
	<div class="insure-login">
		<div class="logo-warp">
			<van-image class="logo" src="http://images.yuntruck.com/default/202307/1690337700795.png" />
			<span class="label">保司推修</span>
		</div>

		<div class="form-warp">
			<van-form ref="form">
				<van-cell-group>
					<van-field
						required
						readonly
						is-link
						size="large"
						v-model="form.locationName"
						label="所在省市"
						placeholder="请选择所在省市"
						@click="handleTriggerLocation"
						:rules="formRules.locationName"
					/>

					<van-field 
						label="查勘员"
						v-model="form.username" 
						placeholder="请输入您的真实姓名" 
						required
						size="large"
						:rules="formRules.username"
					/>

					<van-field 
						label="手机号"
						v-model="form.tel" 
						placeholder="请输入您的手机号"
						required 
						size="large"
						:rules="formRules.tel"
					>
						<van-button 
							v-if="handleTimer.isSend"
							slot="button" 
							type="primary" 
							size="mini" 
							plain
						>{{handleTimer.title}}</van-button>
						<van-button 
							v-else
							slot="button" 
							type="primary" 
							size="mini" 
							plain
							@click="handleSendCode"
						>获取验证码</van-button>						
					</van-field>

					<van-field 
						label="验证码"
						v-model="form.code" 
						placeholder="请输入短信验证码"
						required
						size="large"
						:rules="formRules.code"
					/>

					<van-field 
						label="登录密码"
						type="password"
						v-model="form.passwd" 
						placeholder="请输入登录密码"
						required
						size="large"
						:rules="formRules.passwd"
					/>
				</van-cell-group>
			</van-form>

			<div class="tip">
				<van-icon name="warning-o" /> 没有账号请联系管理员为您开通!
			</div>
		</div>

		<div class="sub-warp">
			<van-button 
				type="primary" 
				@click="handleSubmit"
				size="middle"
				class="btn"
			>登录</van-button>
		</div>

		<!--位置选择-->
		<van-popup 
			v-model="location.show"
			position="bottom"
			:style="{ height: '95vh' }"
		>
			<div class="filter-payload">
				<van-sticky :offset-top="10">
				<div class="filter-search">
					<van-row>
						<van-col :span="24">
							<van-search
								v-model="location.kws"
								placeholder="请输入搜索关键词"
								clearable
								@search="onLocationSearch"
								@blur="onLocationSearch"
								@clear="onLocationClear"
							/>
						</van-col>
					</van-row>
				</div>
				</van-sticky>

				<!--选择位置-->
				<div class="filter-payload">
					<van-index-bar :index-list="location.indexs">
						<template v-for="(item,idx) in location.data">
							<van-index-anchor 
								:index="item.label"
							>{{item.label}}</van-index-anchor>
							
							<template v-for="(lt, lidx) in item.items">
								<van-cell :title="lt.name" 
									@click="handleClickLocation(idx, lidx)"
								/>
							</template>
							
						</template>
					</van-index-bar>
				</div>
			</div>
		</van-popup>

	</div>
</template>
<script type="text/javascript">
import {getAreas} from '@/interface/public/areas';
import {sendTelCode, login} from '@/interface/insure/inauth';
import {setToken,getToken,setInsureInfo} from '@/util/token';
export default {
	data(){
		return {
			isSubmit: false,
			handleTimer:{
				handle:null,
				title:'发送验证码',
				defTitle:'发送验证码',
				disabled: false,
				isSend: false,
				sec:59
			},
			form:{
				locationName: '',
				provId: '',
				cityId: '',
				areaId: '',
				username: '',
				tel: '',
				passwd: '',
				code: '',
				encryCode: '',
			},
			formRules:{
				locationName: [
					{ required: true, message: '请选择所在省市', trigger:'onChange'},
				],
				username: [
					{ required: true, message: '请输入您的真实姓名', trigger:'onChange'},
					{ pattern: /^.{2,10}$/, message: '姓名长度必须大于等于2小于等于10个汉字', trigger:'onChange'},
				],
				tel: [
					{ required: true, message: '请输入手机号', trigger:'onChange'},
					{ pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的手机号', trigger:'onChange'},
				],
				code: [
					{ required: true, message: '请输入验证码', trigger:'onChange'},
					{ pattern: /^[0-9]{6}$/, message: '请输入6位验证码', trigger:'onChange'},
				],
				passwd: [
					{ required: true, message: '请输入登录密码', trigger:'onChange'},
					{ pattern: /^.{6,20}$/, message: '密码长度6~20个字符', trigger:'onChange'},
				],
			},
			location:{
				show:false,
				kws: '',
				indexs:[],
				data:[]
			},
			areaItem: [],
		}
	},
	created: function() {
		let token = getToken();
		if( ('token' in token) && token.token != '' ){
			this.$router.push('/insure/baoxiu');
		}

		this.getAreas();
	},
	methods:{
		handleSendCode: function(){
			if( this.isSubmit )  return false;
			let pattern = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
			if( !pattern.test( this.form.tel ) ){
				return false;
			}

			this.isSubmit = true;
			sendTelCode(this.form.tel)
				.then(res=>{
					if( res.status && ('encryCode' in res.response) ){
						this.form.encryCode = res.response.encryCode;

						this.handleTimer.handle = setInterval(this.telHandleTimer,1000);
						this.handleTimer.isSend = true;

						this.$toast('验证码已发送!');
					}
					this.isSubmit = false;
				})
				.catch(err=>{
					this.isSubmit = false;
				})
		},
		//手机验证码倒计时
		telHandleTimer:function(){
			if( this.handleTimer.sec > 0 ){
				this.handleTimer.title  = this.handleTimer.sec+'s后再发送...';
				this.handleTimer.sec 	= this.handleTimer.sec - 1;
				this.handleTimer.isSend = true;
			}else{
				this.handleTimer.title = this.handleTimer.defTitle;
				this.handleTimer.sec   = 59;
				this.handleTimer.isSend= false;

				clearTimeout( this.handleTimer.handle );
			}
		},
		handleSubmit: function(){
			this.$refs.form.validate()
				.then(() => {
					if( this.form.encryCode == '' ){
						this.$toast('请发送验证码!');
						return false;
					}

					if( this.isSubmit ) return false;

					this.isSubmit = true;
					login( this.form )
						.then(res=>{
							if( res.status && ('token' in res.response) ){
								

								let token = {
									token: res.response.token,
									expire:res.response.expire
								}
								setToken(token);

								let insureInfo = {
									uname: this.form.username,
									tel: this.form.tel,
									locationName: this.form.locationName,
									provId: this.form.provId,
									cityId: this.form.cityId,
									areaId: this.form.areaId
								}
								setInsureInfo(insureInfo);

								this.$router.push('/insure/baoxiu');
							}else{
								this.$toast(res.msg);
							}

							this.isSubmit = false;
						})
						.catch(err=>{
							this.isSubmit = false;
						})
				})
				.catch(() => {
					
				});
		},
		handleTriggerLocation: function(){
			this.location.show = !this.location.show;
		},
		handleClickLocation: function(idx, lidx){
			let current = this.location.data[idx].items[lidx];
				this.location.position = current.name;

			if( current.suffix == '省' ){
				this.form.locationName = current.name;
				this.form.provId = current.value;
				this.form.cityId = 0;
				this.form.areaId = 0;
			}else if( current.suffix == '市' ){
				this.form.locationName = current.name;
				this.form.provId = 0;
				this.form.cityId = current.value;
				this.form.areaId = 0;
			}else if( current.suffix == '区' || current.suffix == '县' ){
				this.form.locationName = current.name;
				this.form.provId = 0;
				this.form.cityId = 0;
				this.form.areaId = current.value;
			}else{
				this.form.locationName = '';
				this.form.provId = 0;
				this.form.cityId = 0;
				this.form.areaId = 0;
			}

			this.handleTriggerLocation();
		},
		onLocationSearch: function(){
			let kws = this.location.kws;
			let data = [];

			this.areaItem.forEach( function(item){
				if( item.name.indexOf(kws) > -1 ){
					data.push(item);
				}
			})

			this.formatArea(data);
		},
		onLocationClear: function(){
			this.formatArea( this.areaItem );
		},
		getAreas: function(){
			getAreas()
				.then(res=>{
					if( res.status && res.response.length > 0 ){
						this.areaItem = res.response;

						let areaItem = [];
						res.response.forEach( function(item){
							if( item.suffix == '省' || item.suffix == '市'){
								areaItem.push(item);
							}
						})

						this.areaItem = areaItem;
						this.formatArea( areaItem );
					}
				})
				.catch(err=>{})
		},
		formatArea: function(data){
			let datas  = {};

			data.forEach( function(item){
				if( item.parent != 50000 && item.initial != ''){
					let key = item.initial.toUpperCase();

					if( !(key in datas) ){
						datas[key] = {
							label: key,
							items: []
						}
					}

					datas[key].items.push(item);
				}
			})

			let indexs 	 = Object.keys(datas).sort();
			let dataSort = [];
			indexs.forEach( function(idx){
				if( idx in datas ){
					dataSort.push(datas[idx]);
				}
			})

			this.location.indexs = indexs;
			this.location.data 	= dataSort;
		},
	}
}
</script>