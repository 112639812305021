/***官网***/
const routerWWW = [
	{
        path: '/home',
        name:'home',
        meta: {
            show: true,
            title:'云卡首页'
        },
        component: () => import('@/pages/Home/index')
    },
    {
        path: '/system',
        name:'system',
        meta: {
            show: true,
            title:'云卡系统'
        },
        component: () => import('@/pages/System/index')
    },
    {
        path: '/union',
        name:'union',
        meta: {
            show: true,
            title:'云卡联盟'
        },
        component: () => import('@/pages/Union/index')
    },
    {
        path: '/join',
        name:'join',
        meta: {
            show: true,
            title:'加盟合作'
        },
        component: () => import('@/pages/Join/index')
    },
    {
        path: '/about',
        name:'about',
        meta: {
            show: true,
            title:'关于云卡'
        },
        component: () => import('@/pages/About/index')
    },
]

export {
	routerWWW
}