import api from '@/interface/baseApi'

//抽奖活动
export function lottery(params) {
    return api({
        url: '/oms/operate/active/lottery',
        method: 'get',
        params:{}
    })
}

//签到
export function signin(params) {
    return api({
        url: '/oms/operate/active/signin',
        method: 'get',
        params:{}
    })
}

//邀请
export function invite(params) {
    return api({
        url: '/oms/operate/active/invite',
        method: 'get',
        params:{}
    })
}
