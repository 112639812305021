<template>
	<div class="insure-baoxiu">
		<div class="baoxiu-total">
			<van-row>
				<van-col span="8">
					<div class="num">{{total.total}}<span class="unit">单</span></div>
					<div class="label">累计推单</div>
				</van-col>
				<van-col span="8">
					<div class="num">{{total.day7}}<span class="unit">单</span></div>
					<div class="label">7日推单</div>
				</van-col>
				<van-col span="8">
					<div class="num">{{total.day15}}<span class="unit">单</span></div>
					<div class="label">15日推单</div>
				</van-col>
			</van-row>
		</div>

		<div class="baoxiu-item">
			<div class="tip"><van-icon name="warning-o" /> 仅展示最近15天的推送单据!</div>

			<div class="payload">

				<van-list
					v-model="querys.loading"
					:finished="querys.finished"
					finished-text="没有更多了"
					@load="onNextPage"
				>
				<template
					v-for="(item,idx) in pageItem"
				>
					<van-cell>
						<template slot="title">
							<span class="title">{{item.code}}</span>
							<span v-if="item.action == 50" class="status">待接修</span>
							<span v-if="item.action == 51" class="status danger">已拒绝</span>
							<!-- <span v-if="item.action == 0" class="status warning">待云卡审核</span> -->
							<span v-if="item.action == 1" class="status primary">门店待接单</span>
							<span v-if="item.action > 1 && item.action < 50" class="status success">门店已接单</span>
						</template>

						<template slot="label">
							<div class="trow">
								<span class="tlabel">保司号:</span>
								<span class="tinfo">{{item.insureCode}}</span>
							</div>
							<div class="trow">
								<span class="tlabel">车辆牌照:</span>
								<span class="tinfo">{{item.idCard}}</span>
							</div>
							<div class="trow">
								<span class="tlabel">报修人:</span>
								<span class="tinfo">{{item.apply}} ({{item.applyTel}})</span>
							</div>
							<div class="trow">
								<span class="tlabel">维修门店:</span>
								<span class="tinfo">{{item.shopName}}</span>
							</div>

						</template>
					</van-cell>
				</template>
				</van-list>
			</div>
		</div>

		<Tabbar />
	</div>
</template>
<script type="text/javascript">
import Tabbar from '@/pages/insure/tabbar';

import {getToken,getInsureInfo} from '@/util/token';
import {get, total} from '@/interface/insure/fixorder';
export default {
	components:{
		Tabbar
	},
	data(){
		return {
			querys:{
				loading: false,
				finished: false,
			},
			uinfo:{
				uname: '',
				tel: '',
			},
			total: {
				total: 0,
				day7: 0,
				day15: 0
			},
			search:{
				tel: '',
				page: 1,
				psize: 15,
				kws: '',
			},
			pageItem: [],
		}
	},
	created: function() {
		let token = getToken();
		if( !('token' in token) || token.token == '' || token.token == null){
			this.$router.push('/insure');
		}

		let insureInfo = getInsureInfo();
		this.uinfo = {
			uname: insureInfo.uname,
			tel: insureInfo.tel
		}

		this.search.tel = insureInfo.tel;

		this.getTotal();
		this.onSearch();
	},
	methods:{
		getTotal: function(){
			let params = {
				tel : this.uinfo.tel
			}

			total(params)
				.then(res=>{
					if( res.status && ('total' in res.response) ){
						let total = res.response;

						this.total = {
							total: total.total,
							day7: total.day7,
							day15: total.day15
						}
					}
				})
				.catch(err=>{})
		},
		onSearch: function(){
			this.search.page = 1;
			this.pageItem = [];

			this.getPageData( this.search );
		},
		onNextPage: function(){
			//仅展示最近15条数据
			return false;

			this.search.page++;
			this.getPageData( this.search );
		},
		getPageData: function(search){
			this.querys.loading = true;
			get(search)
				.then(res=>{
					if( res.status && ('items' in res.response) ){
						let _this = this;
						res.response.items.forEach( function(item){
							_this.pageItem.push(item);
						})
					}

					this.querys.finished = this.pageItem.length >= res.response.total;
					this.querys.loading = false;
				})
				.catch(err=>{
					this.querys.loading = false;
				})
		}
	}
}
</script>