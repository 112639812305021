<template>
    <div class="about-container">
        <div class="head">
            <img class="head-img" src="./images/img_banner_about.jpg" alt="">
            <div class="head-con">
                <div class="head-title">
                    关于我们
                </div>
                <div class="line-box">
                    <div class="line"></div>
                </div>
                <div class="head-content">
                    云卡（北京）网络科技有限公司致力于建立国内商用车后市场服务的互联网平台，通过整合国内线下商用车后市场的维修、配件、服务、物流等相关资源，为商用车客户提供全品牌、全国范围、全方位的售后保障服务;
                </div>
                <div class="head-content">
                    公司将从商用车售后服务入手，解决客户的售后服务保障，形成大数据、大平台，进而扩大至配件供应链管理、车联网、大数据、金融、保险、二手车等等相关业务领域;
                </div>
                <div class="head-content">
                    目前全国加盟合作商用车修理门店超过1000家，目前服务的客户有德邦、中通、百世、狮桥、跨越、安能、福佑、G7等等大型物流公司客户和物流平台；
                </div>
                <div class="head-content">
                    核心创始团队人员均为国内商用车行业从业超过十年以上。
                </div>

            </div>

        </div>
        <div class="body">
            <div class="item">
                <div class="head-title">
                    企业文化
                </div>
                <div class="line-box">
                    <div class="line"></div>
                </div>
                <div class="con-wrapper"  style="margin-bottom: 1rem">
                    <img class="con-img" src="./images/culture01.jpg" alt="">
                    <img class="con-icon" src="./images/icon_yellow_arrow_up.png" alt="">
                    <div class="con-text">
                        我们的使命
                    </div>
                    <div class="con-text-bottom">
                        云卡致力于成为国内商用车后市场领导品牌
                    </div>
                </div>
                <div class="con-wrapper" style="margin-bottom: 1rem">
                    <img class="con-img" src="./images/culture02.jpg" alt="">
                    <img class="con-icon" src="./images/icon_yellow_arrow_up.png" alt="">
                    <div class="con-text">
                        我们的愿景
                    </div>
                    <div class="con-text-bottom">
                        云卡为商用车客户带来更多便利
                    </div>
                </div>
                <div class="con-wrapper" >
                    <img class="con-img" src="./images/culture03.jpg" alt="">
                    <img class="con-icon" src="./images/icon_yellow_arrow_up.png" alt="">
                    <div class="con-text">
                        核心价值观
                    </div>
                    <div class="con-text-bottom">
                        以人为本 诚信正直 互利共赢
                    </div>
                </div>
                <div style="height:1.75rem;background-color: #EEEEEE"></div>
            </div>
            <div class="line-item">
                <div class="head-title">
                    联系我们
                </div>
                <div class="line-box">
                    <div class="line"></div>
                </div>
                <div class="line-card">
                    <div class="card-title">
                        大客户合作
                    </div>
                    <div class="card-con">
                        010-86460909-808
                    </div>
                    <div class="card-con">
                        姜经理
                    </div>
                </div>
                <div class="line-card">
                    <div class="card-title">
                        商务合作
                    </div>
                    <div class="card-con">
                        010-86460909-808
                    </div>
                    <div class="card-con">
                        姜经理
                    </div>
                </div>
                <div class="line-card">
                    <div class="card-title">
                        门店合作
                    </div>
                    <div class="card-con">
                        010-86460909-808
                    </div>
                    <div class="card-con">
                        姜经理
                    </div>
                </div>
                <div class="net-line">
                    <div class="card-con">
                        官网： www.yuntruck.com
                    </div>
                    <div class="card-con">
                        电话： 010-86460909
                    </div>
                    <div class="head-title">
                        下属企业分支机构
                    </div>
                    <div class="net-line-box">
                        <div class="net-line-line"></div>
                    </div>
                    <div class="card-con">
                        上海卡联汽车科技有限公司
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
    import {wxInitGet} from "../../interface/api/wxInit";

    export default {
        name: "index",
        created() {
            this.wxInit()
        },
        methods:{
            wxInit() {
                const data = {
                    url: 'http://h5.www.yuntruck.com/about'
                }

                wxInitGet(data).then(res => {
                    let info = res.response

                    // 需要使用的JS接口列表
                    const wxJsApiList = [
                        'checkJsApi',
                        'updateAppMessageShareData',
                        'updateTimelineShareData',
                        'onMenuShareTimeline',
                        'onMenuShareAppMessage'
                    ];
                    wx.config({
                        debug: false,
                        appId: info.appId,
                        timestamp: info.timestamp,
                        nonceStr: info.nonceStr,
                        signature: info.signature,
                        jsApiList: wxJsApiList
                    });

                    wx.error(function (res) {
                        //console.log('errInfo:',res)
                        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
                    });
                    let  infoUrl =  `${window.location.protocol}//${window.location.host}/redirect.html?redirectUrl=${encodeURIComponent(info.url)}`;
                    var shareAppMessage = {
                        title: "云卡科技官网",
                        desc: "商用车维修连锁服务互联网平台",
                        link: infoUrl,
                        imgUrl: "http://images.yuntruck.com/default/202303/1678439069355.png",
                        success() {
                            //console.log("分享成功")
                        }
                    };

                    wx.ready(function () {
                        wx.checkJsApi({
                            jsApiList: wxJsApiList,
                            success(res) {
                                //console.log("checkJsApi: " + JSON.stringify(res));
                            }
                        });
                        wx.updateAppMessageShareData(shareAppMessage)
                        wx.updateTimelineShareData(shareAppMessage)
                    })

                }).catch((error => {
                    console.log('error:', error)
                }))

            },
        },
    }
</script>

<style scoped lang="less">
    .about-container {
        .head {
            .head-img {
                width: 31.25rem;
                height: 16.67rem;
            }

            .head-con {
                margin: 1.33rem 1.58rem;

                .head-title {
                    font-size: 1.5rem;
                    color: #555555;
                    font-weight: bold;
                    line-height: 2.2rem;
                    text-align: center;
                }

                .line-box {
                    margin-top: 0.75rem;
                    margin-bottom: 1.92rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .line {
                        width: 3.33rem;
                        height: 0.17rem;
                        background-color: #CCCCCC;
                    }
                }

                .head-content {
                    font-size: 1.25rem;
                    color: #666666;
                    line-height: 2rem;
                }

                .head-con-img {
                    margin-top: 0.83rem;
                    margin-bottom: 1.23rem;
                    width: 27.92rem;
                    height: 14.94rem;
                }

            }
        }

        .body {
            .item {
                padding-top: 1.33rem;
                background-color: #EEEEEE;

                .head-title {
                    font-size: 1.5rem;
                    color: #555555;
                    font-weight: bold;
                    line-height: 2.2rem;
                    text-align: center;
                }

                .line-box {
                    margin-top: 0.75rem;
                    margin-bottom: 1.92rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .line {
                        width: 3.33rem;
                        height: 0.17rem;
                        background-color: #CCCCCC;
                    }
                }

                .con-wrapper {
                    background-color: white;
                    margin: 0  1.67rem;
                    position: relative;
                    .con-img {

                        width: 27.92rem;
                        height: 14.75rem;
                    }
                    .con-icon{
                        position: absolute;
                        left: 13rem;
                        top:14rem;
                        bottom:0;
                        width:1.75rem;
                        height: 1.17rem;
                    }
                    .con-text {
                        padding-top: 1rem;
                        text-align: center;
                        font-weight: bold;
                        font-size: 1.33rem;
                        color: #555555
                    }

                    .con-text-bottom {
                        text-align: center;
                        font-size: 1.25rem;
                        color: #666666;
                        line-height: 2rem;
                        padding-bottom: 1.43rem;
                    }
                }

            }
            .line-item{
                .head-title {
                    margin-top: 0.83rem;
                    font-size: 1.5rem;
                    color: #555555;
                    font-weight: bold;
                    line-height: 2.2rem;
                    text-align: center;
                }

                .line-box {
                    margin-top: 0.75rem;
                    margin-bottom: 1.92rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .line {
                        width: 3.33rem;
                        height: 0.17rem;
                        background-color: #CCCCCC;
                    }
                }
                .line-card{
                    margin-top: 0.83rem;
                    text-align: center;
                    margin-left: 1.08rem;
                    padding:1rem 0;
                    width: 29.17rem;
                    height: 7.17rem;
                    background: #FFFFFF;
                    border: 0.15rem solid #EEEEEE;
                    border-bottom: 0.25rem solid  #F08646;;
                    .card-title{
                        font-size: 1.33rem;
                        color: #555555;
                        text-align: center;
                        line-height: 2rem;
                        font-weight: bold;
                        margin-bottom: 0.42rem;
                    }
                    .card-con{
                        font-size: 1.25rem;
                        color: #666666;
                        letter-spacing: 0;
                        text-align: center;
                        line-height: 2rem;

                    }
                }
                .net-line{
                    margin: 1.25rem 0;
                .card-con{
                    font-size: 1.25rem;
                    color: #666666;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 2rem;
                }
                    .head-title {
                        margin-top: 0.83rem;
                        font-size: 1.5rem;
                        color: #555555;
                        font-weight: bold;
                        line-height: 2.2rem;
                        text-align: center;
                    }

                    .net-line-box {
                        margin: 0.75rem 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .net-line-line {
                            width: 3.33rem;
                            height: 0.17rem;
                            background-color: #CCCCCC;
                        }
                    }
                }
            }
        }
    }
</style>
