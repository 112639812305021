/***保司推修***/
const routerInsure = [
    {
        path: '/insure/mine',
        name:'insureMine',
        meta: {
            show: true,
            title:'我的'
        },
        component: () => import('@/pages/insure/ucenter/mine')
    },
	{
        path: '/insure',
        name:'insure',
        meta: {
            show: true,
            title:'保司推修'
        },
        component: () => import('@/pages/insure/index')
    },
    {
        path: '/insure/baoxiu',
        name:'insureBaoxiu',
        meta: {
            show: true,
            title:'保司推修'
        },
        component: () => import('@/pages/insure/baoxiu/index')
    },
    {
        path: '/insure/baoxiuform',
        name:'insureBaoxiuForm',
        meta: {
            show: true,
            title:'保司推修'
        },
        component: () => import('@/pages/insure/baoxiu/form')
    },
]

export {
	routerInsure
}